const colors = {
  greyBg: "#f8f9fa",
  purple: "#64275d",
  black54: "rgba(0,0,0,0.54)",
  black87: "rgba(0,0,0,0.87)",
  white: "rgba(255,255,255,1)",
  lightGrey: "#dddddd",
};

export default colors;
