import React from "react";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import images from "../assets/img/images";

const MusicPublishing = (props) => {
  return (
    <Section
      section="music_publishing"
      setActiveSection={props.setActiveSection}
    >
      <Grid item>
        <img
          src={images.musicPublishing}
          alt="music_publishing"
          style={{ maxWidth: "100%" }}
        />
      </Grid>
    </Section>
  );
};

export default MusicPublishing;
