import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";

const styles = {
  title: {
    color: colors.purple,
  },
  subtitle: {
    color: colors.purple,
    fontWeight: "bold",
  },
};

const Credits = (props) => {
  const { classes, t } = props;

  return (
    <Section section="credits" setActiveSection={props.setActiveSection}>
      <Grid item style={{ textAlign: "left" }}>
        <p>
          <span className={classes.subtitle}>{t("credits_composer")} </span>
          <span>Guy BULANGER</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_lyrics")}</span>
          <br />
          Sophie CHERY (2, 4, 8, 9);
          <br />
          Jon KLEIN (1, 3, 5, 6, 7, 10, 11);
          <br />
          Dione MITCHELL (12)
        </p>
        <p>{t("credits_text1")}</p>

        <p>
          <span className={classes.subtitle}>{t("credits_production")} </span>
          <span>{t("credits_text2")}</span>
        </p>
        <p>
          <span className={classes.subtitle}>
            {t("credits_executive_producer")}{" "}
          </span>
          <span>Marc NEGRONI</span>
        </p>
        <p>
          <span className={classes.subtitle}>
            {t("credits_sound_engineer")}{" "}
          </span>
          <span>Bill SMITH</span>
        </p>
        <Trans>
          <p>
            <span className={classes.subtitle}>{t("credits_recorded")} </span>
            <br />
            {t("credits_text3")}
          </p>
        </Trans>
        <p>
          <span className={classes.subtitle}>{t("credits_images")} </span>
          <span>
            Jon KLEIN, Gordon MIDGLEY, Rob STEVEN WILLIAMS, Keiko YAMAZAKI
          </span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_record_cover")} </span>
          <span>Nawel HARCHE DEPARIS</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_illustration")} </span>
          <span>Katarina VOLODER</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_layout")} </span>
          <span>Sophie CHERY</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_copyright")} </span>
          <span>GBN INTERNATIONAL LTD</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_publishing")} </span>
          <span>GBNmusic.com Ltd</span>
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_album_track")} </span>
          <br />
          1. UNIVERSAL LOVE featuring Jon Klein + L.A. SOUL (5:45)
          <br />
          2. SHALLOW HEAVEN featuring Dionne Mitchell (4:36)
          <br />
          3. TIME TIME AGAIN featuring Eric Phely Moyo (5:13)
          <br />
          4. OUR LOVE HAS GONE ASTRAY featuring Dionne Mitchell (5:36)
          <br />
          5. LOVE ON THE RUN featuring L.A. SOUL (5:13)
          <br />
          6. DOLCE VITA DREAM featuring Eric Phely Moyo (6:26)
          <br />
          7. DO YOU LISTEN TO LOVE SONGS ALONE featuring Dionne Mitchell (4:55)
          <br />
          8. NEVER RETURN featuring Eric Phely Moyo (4:54)
          <br />
          9. LONGING FOR YOU featuring L.A. SOUL (4:06)
          <br />
          10. LOVE ONLY featuring Eric Phely Moyo (5:39)
          <br />
          11. TELL ME MY WAY featuring Dionne Mitchell (5:31)
          <br />
          12. STAR CROSSED featuring Eric Phely Moyo (4:52)
          <br />
          13. OUR LOVE HAVE GONE ASTRAY (RADIO EDIT) featuring Dionne Mitchell
          (5:13)
          <br />
          14. TIME TIME AGAIN (RADIO EDIT) featuring Eric Phely Moyo (4:13)
          <br />
        </p>
        <p>
          <span className={classes.subtitle}>{t("credits_webmaster")} </span>
          <span>
            <a
              href="https://www.linkedin.com/in/nicolas-bernard-6438b9117/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.purple }}
            >
              Nicolas BERNARD
            </a>
          </span>
        </p>
      </Grid>
    </Section>
  );
};

export default withTranslation()(withStyles(styles)(Credits));
