import React from "react";
import { withStyles } from "@material-ui/styles";
import { HashLink as Link } from "react-router-hash-link";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import Grid from "@material-ui/core/Grid";

import Menu from "./Menu";
import MobileMenu from "./MobileMenu";
import Languages from "./Languages";

import images from "../assets/img/images";
import colors from "../assets/style/colors";

const styles = (theme) => ({
  htmlHeader: {
    position: "relative",
    zIndex: "1000",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.1)",
  },
  header: {
    backgroundColor: colors.greyBg,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "120px",
      padding: "0px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "70px",
      padding: "0px 10px",
    },
  },
  headerLogo: {
    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
});

const Header = (props) => {
  const { classes } = props;

  return (
    <header className={classes.htmlHeader}>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs={isWidthDown("md", props.width) ? 10 : false}>
          <Link to="#index">
            <img
              src={images.headerLogo}
              alt="header_logo"
              className={classes.headerLogo}
            />
          </Link>
        </Grid>
        <Grid item xs={isWidthDown("md", props.width) ? 2 : true}>
          {isWidthDown("md", props.width) ? (
            <MobileMenu
              section={props.section}
              changeSection={props.changeSection}
              language={props.language}
              setLanguage={props.setLanguage}
            />
          ) : (
            <Grid container direction="column">
              <Grid item>
                <Languages
                  language={props.language}
                  setLanguage={props.setLanguage}
                />
              </Grid>
              <Grid item>
                <Menu
                  section={props.section}
                  changeSection={props.changeSection}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </header>
  );
};

export default withWidth()(withStyles(styles)(Header));
