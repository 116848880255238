import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import Index from "./Index";
import Intro from "./Intro";
import Videos from "./Videos";
import Casting from "./Casting";
import News from "./News";
import SpecialThanks from "./SpecialThanks";
import Credits from "./Credits";
import Buy from "./Buy";
import WebLinks from "./WebLinks";
import MusicPublishing from "./MusicPublishing";
import Contact from "./Contact";

import colors from "../assets/style/colors";

const styles = (theme) => ({
  container: {
    width: "100%",
    backgroundColor: colors.greyBg,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 125px)",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 180px)",
    },
  },
  mainContent: {
    maxWidth: "1180px",
    margin: "0px auto",
    paddingBottom: "50px",
    backgroundColor: colors.white,
  },
});

const MainContent = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={classes.mainContent}
      >
        <Index setActiveSection={props.setActiveSection} />
        <Intro setActiveSection={props.setActiveSection} />
        <Videos setActiveSection={props.setActiveSection} />
        <Casting setActiveSection={props.setActiveSection} />
        <News setActiveSection={props.setActiveSection} />
        <SpecialThanks setActiveSection={props.setActiveSection} />
        <Credits setActiveSection={props.setActiveSection} />
        <Buy setActiveSection={props.setActiveSection} />
        <WebLinks setActiveSection={props.setActiveSection} />
        <MusicPublishing setActiveSection={props.setActiveSection} />
        <Contact setActiveSection={props.setActiveSection} />
      </Grid>
    </div>
  );
};

export default withStyles(styles)(MainContent);
