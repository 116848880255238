import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import colors from "../assets/style/colors";
import images from "../assets/img/images";

const styles = (theme) => ({
  htmlFooter: {
    position: "relative",
  },
  footer: {
    backgroundColor: colors.greyBg,
    width: "100%",
    boxShadow: "0 -.125rem .25rem rgba(0,0,0,.1)",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 50px",
      height: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      height: "50px",
    },
  },
  footerLogo: {
    margin: "0px 10px",
    height: "50px",
  },
});

const Footer = (props) => {
  const { classes } = props;

  return (
    <footer className={classes.htmlFooter}>
      <Grid container alignItems="flex-end" className={classes.footer}>
        <Grid item>
          <img
            src={images.gbnLogo}
            alt="gbn_logo"
            className={classes.footerLogo}
          />
        </Grid>
        <Grid item>
          <img
            src={images.marcNegroniProd}
            alt="marc_negroni_prod_logo"
            className={classes.footerLogo}
          />
        </Grid>
      </Grid>
    </footer>
  );
};

export default withStyles(styles)(Footer);
