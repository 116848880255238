import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Menu from "./Menu";

import colors from "../assets/style/colors";
import images from "../assets/img/images";
import Languages from "./Languages";

const styles = (theme) => ({
  container: {
    textAlign: "center",
    padding: "0px 50px",
  },
  title: {
    color: colors.purple,
  },
  dialogPaper: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    backgroundColor: colors.greyBg,
    margin: 0,
    padding: "12px",
    borderRadius: 0,
  },
});

const MobileMenu = (props) => {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <img src={images.mobileMenuIcon} alt="mobileMenu" height="35px" />
      </Button>
      <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
        <Grid container direction="column">
          <Grid
            item
            style={{ textAlign: "right", padding: "20px 10px 0px 0px" }}
            onClick={() => setOpen(false)}
          >
            <img src={images.closeIcon} alt="close" />
          </Grid>
          <Grid item>
            <Menu
              mobile={true}
              section={props.section}
              changeSection={props.changeSection}
              close={() => setOpen(false)}
            />
          </Grid>
          <Grid item>
            <Languages
              mobile={true}
              language={props.language}
              setLanguage={props.setLanguage}
              close={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(MobileMenu);
