const fr = {
  // Language
  language_coming_soon:
    "Coming soon... The link for the regional web site you selected will be available in the near future - please continue to ENTER this site for now.",

  // Menu
  menu_index: "Index",
  menu_intro: "Intro",
  menu_videos: "Videos",
  menu_casting: "Casting",
  menu_news: "News",
  menu_special_thanks: "Remerciement Spécial",
  menu_credits: "Credits",
  menu_buy: "En Vente",
  menu_web_links: "Web Links",
  menu_music_publishing: "Music Publishing",
  menu_contact: "Contact",

  // Index
  index_title: "Guy Bulanger Love Songs",
  index_subtitle: "Un album de chansons émouvantes.",

  // Intro
  intro_title: "Intro",
  intro_picture_caption: "(GUY BULANGER 07/07/1950 –12/10/2017)",
  intro_text0:
    "Guy BULANGER est décédé en octobre 2017 alors qu'il composait son prochain projet: une comédie musicale spectaculaire.",
  intro_text1:
    "Guy était le compositeur français de la musique de cet album. Il a écrit de la musique pour une grande variété de chanteurs français. Guy est décédé en octobre 2017 alors qu'il composait son prochain projet: une comédie musicale spectaculaire.",
  intro_text2:
    'Nous avons eu la formidable opportunité de produire cet album: "Guy Bulanger Love Songs", à Los Angeles, avec les meilleurs musiciens et techniciens du monde de la musique, grâce au parrainage de quelques amis.',
  intro_text3:
    "Cet album existe, vous pourriez en avoir un avant-goût en regardant ces cinq vidéos promo que nous avons réalisées.",
  intro_text4:
    "Bien sûr, vous pouvez acheter l'album maintenant distribué via (....... «........ ..... ..... ............ ») sur des services de streaming et des plus grands magasins de musique en ligne où acheter des MP3.",

  // Casting
  casting_title: "Casting",
  casting_guitar: "Guitare",
  casting_bass: "Basse",
  casting_drums: "Batteries",
  casting_keyboards: "Claviers",
  casting_piano: "Piano",
  casting_percussion: "Percussion",
  casting_horn_player: "Trompettiste",
  casting_saxophone: "Saxophone",
  casting_arranger: "Arrangeur",
  casting_singer: "Chanteur",
  casting_engineer: "Ingénieur du son",
  casting_mastering: "Mastering (Los Angeles USA)",
  casting_lyrics: "Lyrics",
  casting_executive_producer: "Producteur exécutif",
  casting_string_section: "Orchestre de cordes",
  casting_la_soul: "L.A SOUL (*) Vocaux et choristes",
  casting_guy_text:
    "Guy était le compositeur français de la musique de cet album. Il a écrit de la musique pour une grande variété de chanteurs français. Guy est décédé en octobre 2017 alors qu'il composait son prochain projet: une comédie musicale spectaculaire.",
  casting_michael_text:
    "Michael a enregistré avec des artistes comme Michael Jackson, Whitney Houston, Céline Dion, 'N Sync, Toni Braxton, Phil Collins, Rod Stewart, Joe Sample, the Scorpions, Vince Neil, Christina Aguilera, Michael Bolton, Mariah Carey, Bette Midler, Madonna , Babyface, En Vogue, Gloria Estefan, Stanley Clarke et Ricky Martin.",
  casting_nathan_text:
    "Nathan A Enregistré, Interprété Et Coécrit Des Chansons Avec Barry White, Anita Baker, The Love Unlimited Orchestra, Babyface, Bb King, Eric Clapton, George Harrison, Elton John, Laura Pausini, Gail Ann Dorsey, Michael Jackson, Stevie Wonder, Bryan Ferry, Savage Garden, Sting, Quincy Jones, Al Jarreau, Kenny Loggins, The Manhattan Transfer, Herbie Hancock, Phil Collins Et Philip Bailey.",
  casting_teddy_text:
    "Directeur musical : Britney Spears, 98 Degrees, Mary Mary, Deborah Cox, Kelly Price, Ann Nesby, Paul Jackson Jr. Still Small Voice Euge Groove Date de jeu Kelly Clarkson Remerciements Stevie Wonder A Time to Love Herbie Hancock Possibilités.",
  casting_tariqh_text:
    "Tariqh a enregistré avec des artistes comme Josh Groban, Christina Aguilera, Elton John, Herbie Hancock, Patti LaBelle, Luther Vandross, Al Jarreau, David Sanborn, Boyz II Men, Stevie Wonder, Smokey Robinson, Alicia Keys, Natalie Cole, Kenny Rogers, Aretha Franklin, Toni Braxton, The Temptations, Jennifer Lopez, Jennifer Love Hewitt, Babyface, David Benoit, Lou Rawls, Carlos Santana, Bette Midler.",
  casting_david_text:
    "David a travaillé avec Patti Austin, Walter Beasley, Joe Sample, George Duke, Al Jarreau, Dizzy Gillespie, David Sanborn, Lalah Hathaway et Marcus Miller. le groupe Sneaky Jones, Eric Clapton.",
  casting_michael2_text:
    "Michael a travaillé avec Christina Aguilera, Ricky Martin, Destiny’s Child, Stevie Wonder, Liza Minelli, Aretha Franklin, Ray Charles. Il a été directeur musical de Madonna, Jennifer Lopez, Rod Stewart, Michael Jackson entre autres. Il a également travaillé avec des artistes de jazz notables tels que Herbie Mann et Herbie Hancock.",
  casting_luis_text:
    'Luis a Joué dans Le Groupe De Fusion Latine Caldera, ainsi qu’avec Madonna, Al Di Meola, Andy Narell, Pat Metheny Group, Le "Band Of Legends" De James Taylor. Alex Acuña, Jaguares, Larry Klimas Et David Garfield, Phil Collins.',
  casting_jerry_text:
    "A arrangé et dirigé les cuivres de cet album <br/> Larry HALL TPT + FLUG <br/> Gary Grant TPT + FLUG <br/> Bill REICHENBACH + TROMBONE <br/> Dan HIGGINS SAX + FLUTE <br/><br/> Jerry orchestrateur a travaillé comme musicien, arrangeur et chef d'orchestre pour Give Me the Night de George Benson; Michael Jackson's Off the Wall, Thriller and Bad, Chaka Khan's I Know You, I Live You, Al Jarreau's Boogie Down, Earth Wind & Fire’s Let’s Groove, Toto’s Rosanna, Teena Marie’s Behind the Groove, Donna Summers ’Bad Girls.",
  casting_dan_text:
    "Joueur de saxophone et d'instruments à vent, Dan a travaillé avec Aerosmith, Stevie Wonder, Neil Diamond, Al Jarreau, Maroon 5, Kenny Loggins, Barry Manilow, Elton John, Go West, The Temptations, Lionel Richie, Joe Cocker, Lisa Stansfield et Eros Ramazzotti. Également connu sous le nom de son saxophone de Bleeding Gums Murphy de The Simpsons.",
  casting_eddie_text:
    "Eddie a arrangé et dirigé les chœurs (elle a travaillé comme chanteuse, arrangeuse et chef d'orchestre. Elle est connue pour son travail sur La La Land (2016), Hidden Figures (2016) et Hairspray Live! (2016).",
  casting_dionne_text:
    "Originaire de Londres, Dionne est finaliste de Xfactor. Ses voix ont été enregistrées aux studios Abbey Road à Londres au Royaume-Uni, ingénieur du son  Sam Okeel.",
  casting_eric_text:
    "Eric est originaire du Zimbabwe, enregistré au Cap Studio (Afrique du Sud). Enregistré au Nut House Studio (CapeTown), ingénieur du son  Beyers Du Toit.",
  casting_string_text:
    "Cordes dirigées par Chris Walden, enregistrées Au Bridge Studio De Los Angeles par l’ingénieur du son  Bill Smith",
  casting_bill_text:
    "Ingénieur du son nominé aux Grammy Awards, Bill Smith a assuré les enregistrements et le mixage de l'album (Première section rythmique) au « Studio Conway » (Los Angeles USA) , (cordes) au « studio The Bridge » (Los Angeles USA) , et enregistrements principalement au studio « The Village « (Los Angeles USA) : castings, sections rythmiques ajoutées, voix, choeurs, solos, re-recording de guitares et de percussions, cuivres, saxos et mixages de l’album.",
  casting_bernie_text:
    "Bernie Grundman est le nom le plus en vue et le plus important et dans le domaine du Mastering de la musique dans le monde.",
  casting_jon_text:
    "Jon était le guitariste de Siouxsie and the Banshees and Sinéad O’Connor. Il est producteur de musique et de vidéo, fondateur du Batcave Club. <br/><br/> Musicienne et graphiste, Sophie est rédactrice en chef d'un magazine d'art international.",
  casting_marc_text:
    "Marc est un producteur réalisateur de disques et metteur en  scène de spectacles français. Il est le producteur exécutif de cet album et a produit musicalement toutes les chansons.",

  // News
  news_title: "News",
  news_subtitle: "Quoi de neuf aujourd'hui :",

  // Special Thanks
  special_thanks_title: "Remerciement Spécial",
  special_thanks_text1:
    "Merci à nos premiers investisseurs Grégory Bulanger, Bertrand Cohen.",
  special_thanks_text2:
    "Un merci spécial à notre ami, fidèle partenaire et investisseur principal Olivier COURIOL.",

  // Credits
  credits_title: "Credits",
  credits_composer: "Compositeur :",
  credits_lyrics: "Textes :",
  credits_production: "Production (direction musicale) :",
  credits_executive_producer: "Executive Producer :",
  credits_recorded: "Album enregistré à :",
  credits_sound_engineer: "Ingénieur du son (enregistrements et  mixages) :",
  credits_images: "Images :",
  credits_record_cover: "Concept pochette :",
  credits_illustration: "Illustration :",
  credits_layout: "Mise en page :",
  credits_copyright: "Copyright:",
  credits_publishing: "Publishing:",
  credits_album_track: "Album Track Listing",
  credits_webmaster: "Webmaster:",
  credits_text1:
    "Paroles et musiques déposées au  « United States Copyright Office, Washington »",
  credits_text2: "Marc NEGRONI",
  credits_text3:
    "CONWAY, Los Angeles (Rhythm section) ingénieurs assistants : Seth Waldmann & Rachel Findlen; <br/> THE BRIDGE, Los Angeles (cordes) ingénieurs assistants: Milton Gutierrez; <br/> THE VILLAGE, Los Angeles (voix, cheurs, cuivres, guitarse, Sax Solos and Mix) ingénieurs assistants: Steve Bone, Eric Denniston, Alex Williams; <br/> ABBEY ROAD STUDIOS, London  (enregistrement voix Dionne Mitchell's) ingénieur : Sam Okeel; <br/> NUT HOUSE STUDIO, CapeTown (enregistrement voix Eric Phely Moyo's) ingénieur : Beyers Du Toit",

  // Buy
  buy_title: "En vente",
  buy_text:
    "À noter :  En vente uniquement aux États-Unis d'Amérique pour le moment.",
  buy_buy_usa: "Acheter aux USA",
  buy_buy_world: "Acheter dans le reste du monde",
  buy_text_world:
    "À noter :  BIENTÔT En vente uniquement dans le reste du monde hors des États-Unis en ce moment.",

  // Web Links
  web_links_title: "Web Links",
};

export default fr;
