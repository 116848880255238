import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";
import images from "../assets/img/images";

const styles = (theme) => ({
  container: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 50px",
    },
  },
  title: {
    color: colors.purple,
  },
  cardContainer: {
    padding: "10px",
    textAlign: "left",
  },
  card: {
    height: "100%",
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: "3px",
    padding: "20px",
  },
  card2: {
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: "3px",
    padding: "20px",
  },
  cardTitle: {
    color: colors.purple,
    textTransform: "uppercase",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "5px",
    marginTop: "0px",
  },
  cardSubtitle: {
    color: colors.purple,
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "5px",
  },
  cardContent: {
    color: colors.black87,
    textAlign: "justify",
    "& p": {
      marginBottom: "2px",
    },
  },
  cardImg: {
    "& img": {
      width: "100%",
      maxWidth: "calc(100vw - 80px)",
      height: "auto",
    },
  },
  cardLinkContainer: {
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  cardLink: {
    color: colors.purple,
    overflowWrap: "anywhere",
  },
  laSoul: {
    maxWidth: "350px",
  },
});

const Casting = (props) => {
  const { classes, t } = props;

  const renderCard = (title, subtitle, content, img, links) => {
    return (
      <Grid item xs={12} sm={6} className={classes.cardContainer}>
        <Grid
          container
          direction="column"
          className={classes.card}
          wrap="nowrap"
        >
          <Grid item>
            <h2 className={classes.cardTitle}>
              <Trans>{title}</Trans>
            </h2>
            <h4 className={classes.cardSubtitle}>{subtitle}</h4>
            <Trans className={classes.cardContent}>{content}</Trans>
          </Grid>
          <Grid item className={classes.cardImg}>
            <img src={img} alt={title} />
          </Grid>
          {links.map((link, index) => (
            <Grid item key={index} className={classes.cardLinkContainer}>
              <a
                href={link}
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderLaSoul = () => {
    return (
      <Grid item xs={12} className={classes.cardContainer}>
        <Grid container className={classes.card2}>
          <Grid
            item
            xs={12}
            sm={5}
            style={{
              margin: "0 -1px",
              paddingRight: "20px",
              flexShrink: 0,
              flex: "0 1 auto",
              webkitFlex: 0,
              flexBasis: isWidthDown("sm", props.width)
                ? "calc(100% - 1px)"
                : "350px",
            }}
          >
            <Grid container direction="column" className={classes.laSoul}>
              <Grid item className={classes.cardImg}>
                <img src={images.castingLASoul} alt="la_soul" />
              </Grid>
              <Grid item className={classes.cardImg}>
                <img src={images.castingLASoul2} alt="la_soul" />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              margin: "0 -1px",
              flexShrink: 0,
              flex: "0 1 auto",
              webkitFlex: 0,
              flexBasis: isWidthDown("sm", props.width)
                ? "calc(100% - 1px)"
                : "calc(100% - 350px)",
            }}
          >
            <h2 className={classes.cardTitle}>L.A SOUL (*) Backing Vocals</h2>
            <div className={classes.cardContent}>
              <p>Bridgette BRYANT</p>
              <a
                href="https://www.youtube.com/watch?v=iIm1lYUXGqU"
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.youtube.com/watch?v=iIm1lYUXGqU
              </a>
              <p>*Carmel ECHOLS</p>
              <a
                href="https://www.discogs.com/fr/artist/963166-Carmel-Echols?type=Credits&subtype=Vocals&filter_anv=0"
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.discogs.com/fr/artist/963166-Carmel-Echols?type=Credits&subtype=Vocals&filter_anv=0
              </a>
              <p>Alexandra BROWN</p>
              <a
                href="https://www.discogs.com/fr/artist/129663-Alex-Brown?filter_anv=1&anv=Alexandra+Brown"
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.discogs.com/fr/artist/129663-Alex-Brown?filter_anv=1&anv=Alexandra+Brown
              </a>
              <p>*Clydene JACKSON</p>
              <a
                href="http://gravity180.net/clydene-jackson"
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                http://gravity180.net/clydene-jackson
              </a>
              <p>*Carmen TWILLIE</p>
              <a
                href="https://en.wikipedia.org/wiki/Carmen_Twillie_(actress)"
                className={classes.cardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://en.wikipedia.org/wiki/Carmen_Twillie_(actress)
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Section section="casting" setActiveSection={props.setActiveSection}>
      <Grid item>
        <Grid container>
          {renderCard(
            "Guy Bulanger",
            "",
            <>
              <p>(07/07/1950 –12/10/2017)</p>
              <p>{t("casting_guy_text")}</p>
            </>,
            images.castingGuy,
            ["https://ggmmusic.com/"]
          )}
          {renderCard(
            "Michael Thompson",
            t("casting_guitar"),
            <p>{t("casting_michael_text")}</p>,
            images.castingMichael,
            ["https://en.wikipedia.org/wiki/Michael_Thompson_(guitarist)"]
          )}
          {renderCard(
            "Nathan Harrell East",
            t("casting_bass"),
            <p>{t("casting_nathan_text")}</p>,
            images.castingNathan,
            ["https://en.wikipedia.org/wiki/Nathan_East"]
          )}
          {renderCard(
            "Teddy Campbell",
            t("casting_drums"),
            <p>{t("casting_teddy_text")}</p>,
            images.castingTeddy,
            ["https://en.wikipedia.org/wiki/Teddy_Campbell"]
          )}
          {renderCard(
            "Tariqh Akoni",
            t("casting_guitar"),
            <p>{t("casting_tariqh_text")}</p>,
            images.castingTariqh,
            ["https://en.wikipedia.org/wiki/Tariqh_Akoni"]
          )}
          {renderCard(
            "David Delhomme",
            t("casting_keyboards"),
            <p>{t("casting_david_text")}</p>,
            images.castingDavid,
            [
              "https://www.discogs.com/fr/artist/1010043-David-Delhomme?anv=Dave+Delhomme&filter_anv=1",
            ]
          )}
          {renderCard(
            "Michael Bearden",
            t("casting_piano"),
            <p>{t("casting_michael2_text")}</p>,
            images.castingMichael2,
            ["http://michaelbearden.com/about/"]
          )}
          {renderCard(
            "Luis Conte",
            t("casting_percussion"),
            <p>{t("casting_luis_text")}</p>,
            images.castingLuis,
            ["https://luisconte.com/luis-conte-bio-credits"]
          )}
          {renderCard(
            "Jerry Hey",
            t("casting_horn_player"),
            <p>{t("casting_jerry_text")}</p>,
            images.castingJerry,
            ["https://en.wikipedia.org/wiki/Jerry_Hey"]
          )}
          {renderCard(
            "Dan Higgins",
            t("casting_saxophone"),
            <p>{t("casting_dan_text")}</p>,
            images.castingDan,
            ["https://en.wikipedia.org/wiki/Dan_Higgins"]
          )}
          {renderCard(
            "Eddie Lehman Boddicker",
            t("casting_arranger"),
            <p>{t("casting_eddie_text")}</p>,
            images.castingEddie,
            ["https://www.imdb.com/name/nm0090798/?ref_=nm_wrk"]
          )}
          {renderCard(
            "Dionne Mitchell",
            t("casting_singer"),
            <p>{t("casting_dionne_text")}</p>,
            images.castingDionne,
            ["https://ggmmusic.com/"]
          )}
          {renderCard(
            "Eric Phely Moyo",
            t("casting_singer"),
            <p>{t("casting_eric_text")}</p>,
            images.castingEric,
            ["https://ggmmusic.com/"]
          )}
          {renderCard(
            t("casting_string_section"),
            "",
            <p>{t("casting_string_text")}</p>,
            images.castingString,
            ["http://www.thebridgerecording.com/pictures.html"]
          )}
          {renderCard(
            "Bill Smith",
            t("casting_engineer"),
            <p>{t("casting_bill_text")}</p>,
            images.castingBill,
            [
              "http://www.home.earthlink.net/~wgsmith/page2/music.html",
              "http://www.conwayrecording.com/",
              "http://thebridgerecording.com/",
              "https://villagestudios.com/",
            ]
          )}
          {renderCard(
            "Bernie Grundman",
            t("casting_mastering"),
            <p>{t("casting_bernie_text")}</p>,
            images.castingBernie,
            [
              "https://www.discogs.com/fr/artist/307942-Bernie-Grundman?type=Credits&subtype=Technical&filter_anv=0",
              "https://www.berniegrundmanmastering.com/",
            ]
          )}
          {renderCard(
            "Jon Klein <br/> Sophie Chery",
            t("casting_lyrics"),
            <p>{t("casting_jon_text")}</p>,
            images.castingJon,
            ["https://en.wikipedia.org/wiki/Jon_Klein_(musician)"]
          )}
          {renderCard(
            "Marc Negroni",
            t("casting_executive_producer"),
            <p>{t("casting_marc_text")}</p>,
            images.castingMarc,
            [
              "https://www.discogs.com/fr/artist/825803-Marc-N%C3%A9groni?page=1",
            ]
          )}
          {renderLaSoul()}
        </Grid>
      </Grid>
    </Section>
  );
};

export default withWidth()(withTranslation()(withStyles(styles)(Casting)));
