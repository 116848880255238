import React from "react";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import images from "../assets/img/images";

const Intro = (props) => {
  const { t } = props;

  return (
    <Section section="intro" setActiveSection={props.setActiveSection}>
      <Grid item style={{ textAlign: "left" }}>
        <p>{t("intro_text0")}</p>
      </Grid>
      <Grid item style={{ margin: "20px 0px 10px" }}>
        <img
          src={images.introPicture}
          alt="intro_picture"
          style={{ maxWidth: "100%" }}
        />
      </Grid>
      <Grid item>{t("intro_picture_caption")}</Grid>
      <Grid item style={{ textAlign: "justify" }}>
        <p>{t("intro_text1")}</p>
        <p>{t("intro_text2")}</p>
        <p>{t("intro_text3")}</p>
        <p>{t("intro_text4")}</p>
      </Grid>
    </Section>
  );
};

export default withTranslation()(Intro);
