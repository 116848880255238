import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 478,
      md: 991,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 8,
  //   palette: {
  //     background: {
  //       default: "#f6f6f6",
  //     },
  //     primary: {
  //       main: colors.primary,
  //     },
  //     secondary: {
  //       main: "#eb3c39",
  //     },
  //   },
  //   overrides: {
  //     MuiButton: {
  //       contained: {
  //         borderRadius: "2px",
  //         boxShadow: "none",
  //         marginTop: "32px",
  //         marginBottom: "27px",
  //       },
  //       containedPrimary: {
  //         "&:hover": {
  //           //backgroundColor: "#006064"
  //         },
  //       },
  //     },
  //     MuiFormControl: {
  //       marginNormal: {
  //         marginTop: 0,
  //         marginBottom: 0,
  //       },
  //     },
  //     MuiFormLabel: {
  //       root: {
  //         marginTop: "16px",
  //       },
  //     },
  //     MuiLink: {
  //       root: {
  //         cursor: "pointer",
  //         fontSize: "0.875em",
  //         lineHeight: "1.0",
  //       },
  //     },
  //     MuiListItemText: {
  //       root: {
  //         marginTop: 0,
  //         marginBottom: 0,
  //       },
  //     },
  //     MuiOutlinedInput: {
  //       notchedOutline: {
  //         borderRadius: "2px",
  //       },
  //     },
  //     MuiTextField: {
  //       root: {
  //         "& .MuiOutlinedInput-root": {
  //           "&:hover fieldset": {
  //             borderColor: "#cecece",
  //           },
  //           "&.Mui-focused fieldset": {
  //             borderColor: "#212121",
  //             borderWidth: "1px",
  //           },
  //         },
  //       },
  //     },
  //     MuiTypography: {
  //       h3: {
  //         fontSize: "1.0em",
  //         fontWeight: "500",
  //         marginBottom: "10px",
  //       },
  //     },
  //   },
});

export default theme;
