import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";

const styles = {
  title: {
    color: colors.purple,
  },
};

const Contact = (props) => {
  const { classes } = props;

  return (
    <Section section="contact" setActiveSection={props.setActiveSection}>
      <Grid item style={{ textAlign: "left" }}>
        GBN INTERNATIONAL Ltd
        <br />
        <br />
        E-mail:{" "}
        <a
          href="mailto:GBLSnegroniprod2020@gmail.com"
          className={classes.title}
        >
          GBLSnegroniprod2020@gmail.com
        </a>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(Contact);
