import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Grid from "@material-ui/core/Grid";

import colors from "../assets/style/colors";

const styles = (theme) => ({
  menuContainer: {
    marginBottom: "-30px",
  },
  menuItem: {
    margin: "0px 10px",
    cursor: "pointer",
  },
  menuItemMobile: {
    margin: "8px 10px",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
  },
  linkActive: {
    fontWeight: "bold",
    color: colors.purple,
    borderBottom: `3px solid ${colors.purple}`,
    textDecoration: "none",
    paddingBottom: "3px",
  },
  link: {
    textDecoration: "none",
    color: colors.black54,
    "&:hover": {
      color: colors.black87,
    },
  },
});

const Menu = (props) => {
  const { classes, t, mobile = false, section, changeSection } = props;

  return (
    <Grid
      container
      justify="flex-end"
      alignItems={mobile ? "flex-start" : "center"}
      className={mobile ? "" : classes.menuContainer}
      direction={mobile ? "column" : "row"}
    >
      {[
        "index",
        "intro",
        "videos",
        "casting",
        "news",
        "special_thanks",
        "credits",
        "buy",
        "web_links",
        "music_publishing",
        "contact",
      ].map((item, index) => (
        <Grid
          item
          key={index}
          className={mobile ? classes.menuItemMobile : classes.menuItem}
          onClick={() => {
            changeSection(item);
            if (mobile) props.close();
          }}
        >
          <Link
            to={`#${item}`}
            className={section === item ? classes.linkActive : classes.link}
            style={{ width: mobile ? "100%" : "" }}
          >
            {t(`menu_${item}`)}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(Menu));
