import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";
import images from "../assets/img/images";

const styles = {
  title: {
    color: colors.purple,
  },
};

const News = (props) => {
  const { classes, t } = props;

  return (
    <Section section="news" setActiveSection={props.setActiveSection}>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        style={{ marginBottom: "20px", textAlign: "left" }}
      >
        <img
          src={images.blackLivesMatter}
          alt="black_lives_matter"
          style={{ maxWidth: "90%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} style={{ textAlign: "left" }}>
        <img
          src={images.gretaThunberg}
          alt="greta_thunberg"
          style={{ maxWidth: "90%" }}
        />
      </Grid>
      <Grid item className={classes.title} style={{ textAlign: "left" }}>
        <h2>{t("news_subtitle")}</h2>
      </Grid>
    </Section>
  );
};

export default withTranslation()(withStyles(styles)(News));
