const en = {
  // Language
  language_coming_soon:
    "Coming soon... The link for the regional web site you selected will be available in the near future - please continue to ENTER this site for now.",

  // Menu
  menu_index: "Index",
  menu_intro: "Intro",
  menu_videos: "Videos",
  menu_casting: "Casting",
  menu_news: "News",
  menu_special_thanks: "Special Thanks",
  menu_credits: "Credits",
  menu_buy: "Buy",
  menu_web_links: "Web Links",
  menu_music_publishing: "Music Publishing",
  menu_contact: "Contact",

  // Index
  index_title: "Guy Bulanger Love Songs",
  index_subtitle: "An album of emotive songs.",

  // Intro
  intro_title: "Intro",
  intro_picture_caption: "(GUY BULANGER 07/07/1950 –12/10/2017)",
  intro_text0:
    "Guy BULANGER passed away in October 2017 while he was composing his next project: a spectacular musical.",
  intro_text1:
    "Guy was the French composer of the music on this album. He wrote music for a wide variety of French singers. Guy passed away in October 2017 while he was composing his next project: a spectacular musical.",
  intro_text2:
    'We had the amazing opportunity to produce this album: "Guy Bulanger Love Songs", in Los Angeles, with the best musicians and technicians in the music business, thanks to the sponsorship of a few friends.',
  intro_text3:
    "This album exists, you might have a taste of it by watching these few promo videos we realized.",
  intro_text4:
    "Of course, you can buy the album now distributed through (....... « ........ ..... ..... ............ ») from streaming services and the biggest online music stores where to buy MP3s.",

  // Casting
  casting_title: "Casting",
  casting_guitar: "Guitar",
  casting_bass: "Bass",
  casting_drums: "Drums",
  casting_keyboards: "Keyboards",
  casting_piano: "Piano",
  casting_percussion: "Percussion",
  casting_horn_player: "Horn Player",
  casting_saxophone: "Saxophone",
  casting_arranger: "Arranger",
  casting_singer: "Singer",
  casting_engineer: "Engineer",
  casting_mastering: "Mastering (Los Angeles USA)",
  casting_lyrics: "Lyrics",
  casting_executive_producer: "Executive Producer",
  casting_string_section: "String Section",
  casting_la_soul: "L.A SOUL (*) Backing Vocals",
  casting_guy_text:
    "Guy was the French composer of the music on this album. He wrote music for a wide variety of French singers. Guy passed away in October 2017 while he was composing his next project: a spectacular musical.",
  casting_michael_text:
    "Michael has recorded with artists including Michael Jackson, Whitney Houston, Celine Dion, ‘N Sync, Toni Braxton, Phil Collins, Rod Stewart, Joe Sample, the Scorpions, Vince Neil, Christina Aguilera, Michael Bolton, Mariah Carey, Bette Midler, Madonna, Babyface, En Vogue, Gloria Estefan, Stanley Clarke, and Ricky Martin.",
  casting_nathan_text:
    "Nathan has recorded, performed and cowritten songs with Barry White, Anita Baker, The Love Unlimited Orchestra, Babyface, B.B. King, Eric Clapton, George Harrison, Elton John, Laura Pausini, Gail Ann Dorsey, Michael Jackson, Stevie Wonder, Bryan Ferry, Savage Garden, Sting, Quincy Jones, Al Jarreau, Kenny Loggins, The Manhattan Transfer, Herbie Hancock, Phil Collins and Philip Bailey.",
  casting_teddy_text:
    "Musical director: Britney Spears, 98 Degrees, Mary Mary, Deborah Cox, Kelly Price, Ann Nesby, Paul Jackson Jr. <i>Still Small Voice</i> Euge Groove <i>Play Date</i> Kelly Clarkson <i>Thankful</i> Stevie Wonder <i>A Time to Love</i> Herbie Hancock <i>Possibilities</i>",
  casting_tariqh_text:
    "Tariqh has recorded with artists including Josh Groban, Christina Aguilera, Elton John, Herbie Hancock, Patti LaBelle, Luther Vandross, Al Jarreau, David Sanborn, Boyz II Men, Stevie Wonder, Smokey Robinson, Alicia Keys, Natalie Cole, Kenny Rogers, Aretha Franklin, Toni Braxton, The Temptations, Jennifer Lopez, Jennifer Love Hewitt, Babyface, David Benoit, Lou Rawls, Carlos Santana, Bette Midler.",
  casting_david_text:
    "David has worked with Patti Austin, Walter Beasley, Joe Sample, George Duke, Al Jarreau, Dizzy Gillespie, David Sanborn, Lalah Hathaway, and Marcus Miller. the band Sneaky Jones, Eric Clapton.",
  casting_michael2_text:
    "Michael has worked with Christina Aguilera, Ricky Martin, Destiny’s Child, Stevie Wonder, Liza Minelli, Aretha Franklin, Ray Charles. He has been musical director for Madonna, Jennifer Lopez, Rod Stewart, Michael Jackson among others. He has also worked with such notable jazz artists as Herbie Mann and Herbie Hancock.",
  casting_luis_text:
    "Luis played in the Latin fusion band Caldera, Madonna, Al Di Meola, Andy Narell, Pat Metheny Group , James Taylor’s “Band of Legends.” Alex Acuña, Jaguares, Larry Klimas, and David Garfield, Phil Collins.",
  casting_jerry_text:
    "Arranged and conducted the brass <br/> Larry HALL TPT + FLUG <br/> Gary Grant TPT + FLUG <br/> Bill REICHENBACH + TROMBONE <br/> Dan HIGGINS SAX + FLUTE <br/><br/> Jerry has worked as musician, arranger and conductor for George Benson’s <i>Give Me the Night;</i> Michael Jackson’s <i>Off the Wall, Thriller</i> and <i>Bad</i>, Chaka Khan’s <i>I Know You, I Live You</i>, Al Jarreau’s <i>Boogie Down</i>, Earth Wind & Fire’s <i>Let’s Groove</i>, Toto’s <i>Rosanna</i>, Teena Marie’s <i>Behind the Groove</i>, Donna Summers’ <i>Bad Girls</i>.",
  casting_dan_text:
    "Saxophone and woodwind player, Dan has worked with Aerosmith, Stevie Wonder, Neil Diamond, Al Jarreau, Maroon 5, Kenny Loggins, Barry Manilow, Elton John, Go West, The Temptations, Lionel Richie, Joe Cocker, Lisa Stansfield and Eros Ramazzotti. Also known as the saxophone sound of Bleeding Gums Murphy from The Simpsons.",
  casting_eddie_text:
    "Eddie has arranged and conducted the backing vocals (she has worked as singer, arranger and conductor. She is known for her work on <i>La La Land</i> (2016), <i>Hidden Figures</i> (2016) and <i>Hairspray Live!</i> (2016).",
  casting_dionne_text:
    "From London, Dionne is a finalist in Xfactor. Her vocals were recorded at Abbey Road Studios in London UK, engineered by Sam Okeel.",
  casting_eric_text:
    "Eric is from Zimbabwe, recorded at Le Cap Studio (South Africa). Recorded at Nut House Studio (CapeTown), engineeried by Beyers Du Toit.",
  casting_string_text:
    "Strings directed par Chris Walden, recorded at The Bridge Studio Los Angeles.",
  casting_bill_text:
    "Grammy Award nominated engineer was engineering and mixing the album at Studio Conway (first rhythm section) at studio the Bridge (strings) and mainly at The Village (Los Angeles USA) (castings, added rhythm sections, vocals, backing vocals, solos, added guitars and percussions, brass, sax, and mix).",
  casting_bernie_text:
    "Bernie Grundman is the most prominent name in music Mastering worldwide.",
  casting_jon_text:
    "Jon was Siouxsie and the Banshees and Sinéad O’Connor’s guitarist. He is a music and video producer, founder of the Batcave Club. <br/><br/> Sophie is a musician and graphic artist, and she is the art editor for an international art magazine.",
  casting_marc_text:
    "Marc is a french record and stage producer, is executive producer of this album and did musically produced all songs.",

  // News
  news_title: "News",
  news_subtitle: "What's new today:",

  // Special Thanks
  special_thanks_title: "Special Thanks",
  special_thanks_text1:
    "Thanks to our first investors Grégory Bulanger, Bertrand Cohen.",
  special_thanks_text2:
    "Special Thanks to our faithful and leading investor partner Olivier COURIOL.",

  // Credits
  credits_title: "Credits",
  credits_composer: "Composer:",
  credits_lyrics: "Lyrics:",
  credits_production: "Production (musical direction):",
  credits_executive_producer: "Executive Producer:",
  credits_recorded: "Recorded at:",
  credits_sound_engineer:
    "Sound engineer responsible for engineering and mixing the album:",
  credits_images: "Images:",
  credits_record_cover: "Record Cover Concept:",
  credits_illustration: "Illustration:",
  credits_layout: "Layout:",
  credits_copyright: "Copyright:",
  credits_publishing: "Publishing:",
  credits_album_track: "Album Track Listing",
  credits_webmaster: "Webmaster:",
  credits_text1:
    "All songs registred with United States Copyright Office, Washington.",
  credits_text2: "All songs produced by Marc NEGRONI",
  credits_text3:
    "CONWAY, Los Angeles (Rhythm section) Assistant engineers: Seth Waldmann & Rachel Findlen;<br/> THE BRIDGE, Los Angeles (Strings) Assistant engineer: Milton Gutierrez; <br/> THE VILLAGE, Los Angeles (Vocals, Backing vocals, Brass, Added guitars, Sax Solos and Mix) Assistant engineers: Steve Bone, Eric Denniston, Alex Williams; <br/> ABBEY ROAD STUDIOS, London (Dionne Mitchell's vocal recording) Additional Engineering: Sam Okeel; <br/> NUT HOUSE STUDIO, CapeTown (Eric Phely Moyo's vocal recording) Additional Engineering: Beyers Du Toit",

  // Buy
  buy_title: "Buy",
  buy_text: "Note: On sale only in the United States Of America at this time.",
  buy_buy_usa: "Buy in USA",
  buy_buy_world: "Buy in the world",
  buy_text_world:
    "Note: SOON On sale only in the rest of the world out of USA at this time.",

  // Web Links
  web_links_title: "Web Links",
};

export default en;
