import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";

const styles = {
  title: {
    color: colors.purple,
  },
};

const SpecialThanks = (props) => {
  const { classes, t } = props;

  return (
    <Section section="special_thanks" setActiveSection={props.setActiveSection}>
      <Grid item className={classes.title} style={{ textAlign: "left" }}>
        <h3>{t("special_thanks_text1")}</h3>
      </Grid>
      <Grid item className={classes.title} style={{ textAlign: "left" }}>
        <h3>{t("special_thanks_text2")}</h3>
      </Grid>
    </Section>
  );
};

export default withTranslation()(withStyles(styles)(SpecialThanks));
