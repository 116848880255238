import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";
import images from "../assets/img/images";

const styles = {
  title: {
    color: colors.purple,
  },
  service: {
    margin: "0px 20px",
    "& p": {
      color: colors.purple,
      fontWeight: "bold",
      fontSize: "20px",
      marginTop: "0px",
    },
  },
};

const Buy = (props) => {
  const { classes, t } = props;

  return (
    <Section section="buy" setActiveSection={props.setActiveSection}>
      <Grid item style={{ textAlign: "left" }}>
        <h2 className={classes.title}>{t("buy_buy_usa")}</h2>
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        <img src={images.gblovesongsCover} alt="album_cover" width="200px" />
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        {[
          "https://www.amazon.com/",
          "https://www.allmusic.com/",
          "https://www.deezer.com/",
          "https://play.google.com/",
          "https://itunes.apple.com/",
          "https://www.pandora.com/",
          "https://www.youtube.com",
          "https://soundcloud.com/",
          "https://open.spotify.com/",
          "https://www.tiktok.com/music/",
        ].map((service, index) => (
          <span
            key={index}
            style={{ marginRight: "5px", color: colors.purple }}
          >
            <a href={service} style={{ color: colors.purple }}>
              {service}
            </a>
            {", "}
          </span>
        ))}
      </Grid>
      <Grid item style={{ textAlign: "left", marginTop: "10px" }}>
        {t("buy_text")}
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        <h2 className={classes.title}>{t("buy_buy_world")}</h2>
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        {t("buy_text_world")}
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        <img src={images.indexPicture} alt="album_cover" width="200px" />
      </Grid>
    </Section>
  );
};

export default withTranslation()(withStyles(styles)(Buy));
