const images = {
  en: require("./en_flag.gif"),
  fr: require("./fr_flag.gif"),
  es: require("./es_flag.gif"),
  gm: require("./gm_flag.gif"),
  it: require("./it_flag.gif"),
  chi: require("./chi_flag.gif"),
  jp: require("./jp_flag.gif"),
  ne: require("./ne_flag.gif"),
  us: require("./us_flag.gif"),
  em: require("./em_flag.gif"),
  headerLogo: require("./header_logo.png"),
  mobileMenuIcon: require("./mobile_menu.png"),
  closeIcon: require("./close_icon.svg"),
  indexPicture: require("./index_picture.jpg"),
  gbnLogo: require("./gbn.jpg"),
  marcNegroniProd: require("./marc_negroni_productions.gif"),
  introPicture: require("./intro_picture.jpg"),
  castingGuy: require("./casting_guy.jpg"),
  castingMichael: require("./casting_michael.jpg"),
  castingNathan: require("./casting_nathan.jpg"),
  castingTeddy: require("./casting_teddy.jpg"),
  castingTariqh: require("./casting_tariqh.jpg"),
  castingDavid: require("./casting_david.jpg"),
  castingMichael2: require("./casting_michael2.jpg"),
  castingLuis: require("./casting_luis.jpg"),
  castingJerry: require("./casting_jerry.jpg"),
  castingDan: require("./casting_dan.jpg"),
  castingEddie: require("./casting_eddie.jpg"),
  castingDionne: require("./casting_dionne.jpg"),
  castingEric: require("./casting_eric.jpg"),
  castingString: require("./casting_string.jpg"),
  castingBill: require("./casting_bill.jpg"),
  castingBernie: require("./casting_bernie.jpg"),
  castingJon: require("./casting_jon.jpg"),
  castingMarc: require("./casting_marc.jpg"),
  castingLASoul: require("./casting_la_soul.jpg"),
  castingLASoul2: require("./casting_la_soul2.jpg"),
  facebook: require("./facebook.jpg"),
  musicPublishing: require("./music_publishing.jpg"),
  blackLivesMatter: require("./black_lives_matter.png"),
  gretaThunberg: require("./greta_thunberg.jpeg"),
  gblovesongsCover: require("./gblovesongs_cover.jpg"),
};

export default images;
