import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { HashRouter } from "react-router-dom";

import Header from "./Header";
import MainContent from "./MainContent";
import Footer from "./Footer";

import theme from "../assets/style/theme";

import en from "../ressources/en";
import fr from "../ressources/fr";
import it from "../ressources/it";
import es from "../ressources/es";

const App = () => {
  const [language, setLanguage] = React.useState("en");
  const [section, setSection] = React.useState("index");

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      es: {
        translation: es,
      },
      us: {
        translation: en,
      },
    },
    lng: language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  React.useEffect(() => {
    setSection("index");
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <HashRouter>
        <Header
          language={language}
          setLanguage={setLanguage}
          section={section}
          changeSection={setSection}
        />
        <MainContent setActiveSection={setSection} />
        <Footer />
      </HashRouter>
    </MuiThemeProvider>
  );
};

export default App;
