import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import images from "../assets/img/images";

const styles = {
  productionsIcon: {
    margin: "10px",
    "& img": {
      height: "70px",
    },
  },
};

const Index = (props) => {
  const { classes, t } = props;

  return (
    <Section section="index" setActiveSection={props.setActiveSection}>
      <Grid item style={{ marginTop: "-20px" }}>
        <p>{t("index_subtitle")}</p>
      </Grid>
      <Grid item>
        <img
          src={images.indexPicture}
          alt="index"
          width="550px"
          style={{ maxWidth: "100%" }}
        />
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid item className={classes.productionsIcon}>
            <img src={images.gbnLogo} alt="production" />
          </Grid>
          <Grid item className={classes.productionsIcon}>
            <img src={images.marcNegroniProd} alt="production" />
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

export default withTranslation()(withStyles(styles)(Index));
