import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import images from "../assets/img/images";
import colors from "../assets/style/colors";

const styles = (theme) => ({
  languagesContainer: {
    marginBottom: "20px",
  },
  languagesContainerMobile: {
    marginTop: "30px",
  },
  flagItem: {
    margin: "0px 10px",
    "& img": {
      boxShadow: "0 .125rem .25rem rgba(0,0,0,.15)",
      height: "16px",
    },
  },
  tooltip: {
    fontSize: "14px",
    backgroundColor: colors.purple,
    color: colors.white,
  },
});

const lang = [
  {
    name: "en",
    available: true,
  },
  {
    name: "us",
    available: true,
  },
  {
    name: "fr",
    available: true,
  },
  {
    name: "es",
    available: true,
  },
  {
    name: "it",
    available: true,
  },
  {
    name: "gm",
    available: false,
  },
  {
    name: "jp",
    available: false,
  },
  {
    name: "ne",
    available: false,
  },
  {
    name: "chi",
    available: false,
  },
  {
    name: "em",
    available: false,
  },
];

const Languages = (props) => {
  const { classes, t, mobile = false } = props;

  return (
    <Grid
      container
      alignItems="center"
      justify={mobile ? "flex-start" : "flex-end"}
      className={
        mobile ? classes.languagesContainerMobile : classes.languagesContainer
      }
    >
      {lang.map((language, index) => (
        <Grid
          item
          key={index}
          className={classes.flagItem}
          style={{
            cursor: language.available ? "pointer" : "default",
            height: mobile ? "50px" : "",
            width: mobile ? "35px" : "",
            textAlign: mobile ? "center" : "",
          }}
          onClick={() => {
            if (language.available) {
              props.setLanguage(language.name);
              if (mobile) props.close();
            }
          }}
        >
          {language.available ? (
            <img src={images[language.name]} alt={`${language.name}_flag`} />
          ) : (
            <Tooltip
              title={t("language_coming_soon")}
              classes={{ tooltip: classes.tooltip }}
            >
              <img src={images[language.name]} alt={`${language.name}_flag`} />
            </Tooltip>
          )}
        </Grid>
      ))}
      <Grid item></Grid>
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(Languages));
