const it = {
  // Language
  language_coming_soon:
    "Coming soon... The link for the regional web site you selected will be available in the near future - please continue to ENTER this site for now.",

  // Menu
  menu_index: "Index",
  menu_intro: "Intro",
  menu_videos: "Videos",
  menu_casting: "Casting",
  menu_news: "News",
  menu_special_thanks: "Ringraziamenti speciali",
  menu_credits: "Credits",
  menu_buy: "In Vendita",
  menu_web_links: "Web Links",
  menu_music_publishing: "Music Publishing",
  menu_contact: "Contact",

  // Index
  index_title: "Guy Bulanger Love Songs",
  index_subtitle: "Un album di canzoni emotive.",

  // Intro
  intro_title: "Intro",
  intro_picture_caption: "(GUY BULANGER 07/07/1950 –12/10/2017)",
  intro_text0:
    "Guy BULANGER è morto nell'ottobre 2017 mentre stava componendo il suo prossimo progetto: un musical spettacolare.",
  intro_text1:
    "Guy è stato il compositore francese della musica per questo album. Ha scritto musica per un'ampia varietà di cantanti francesi. Guy è morto nell'ottobre 2017 mentre componeva il suo prossimo progetto: un musical spettacolare.",
  intro_text2:
    'Abbiamo avuto la grande opportunità di produrre questo album: "Guy Bulanger Love Songs", a Los Angeles, con i migliori musicisti e tecnici nel mondo della musica, grazie alla sponsorizzazione di alcuni amici. Questo album esiste, potresti averne un assaggio guardando questi pochi video promozionali che abbiamo realizzato.',
  intro_text3:
    "Questo album esiste, potresti averne un assaggio guardando questi cinque video promozionali che abbiamo realizzato.",
  intro_text4:
    'Ovviamente, puoi acquistare l\'album ora distribuito tramite (....... "........ ..... ..... ............") Sui servizi di streaming e sui più grandi negozi di musica online dove è possibile acquistare MP3.',

  // Casting
  casting_title: "Casting",
  casting_guitar: "Chitarra",
  casting_bass: "Basso",
  casting_drums: "Batterie",
  casting_keyboards: "Tastiere",
  casting_piano: "Pianoforte",
  casting_percussion: "Percussione",
  casting_horn_player: "Trombettista",
  casting_saxophone: "Sassofono",
  casting_arranger: "Arrangiatore",
  casting_singer: "Cantante",
  casting_engineer: "Ingegnere del suono",
  casting_mastering: "Mastering (Los Angeles USA)",
  casting_lyrics: "Testi",
  casting_executive_producer: "Produttore esecutivo",
  casting_string_section: "ORCHESTRA D'ARCHI",
  casting_la_soul: "L.A SOUL (*) Vocals e Choristers",
  casting_guy_text:
    "Guy era il compositore francese della musica per questo album. Ha scritto musica per un'ampia varietà di cantanti francesi. Guy è morto nell'ottobre 2017 mentre componeva il suo prossimo progetto: un musical spettacolare.",
  casting_michael_text:
    "Michael ha registrato con artisti come Michael Jackson, Whitney Houston, Céline Dion, 'N Sync, Toni Braxton, Phil Collins, Rod Stewart, Joe Sample, Scorpions, Vince Neil, Christina Aguilera, Michael Bolton, Mariah Carey, Bette Midler, Madonna, Babyface, En Vogue, Gloria Estefan, Stanley Clarke e Ricky Martin.",
  casting_nathan_text:
    "Nathan ha registrato, recitato e scritto canzoni con Barry White, Anita Baker, The Love Unlimited Orchestra, Babyface, Bb King, Eric Clapton, George Harrison, Elton John, Laura Pausini, Gail Ann Dorsey, Michael Jackson, Stevie Wonder, Bryan Ferry , Savage Garden, Sting, Quincy Jones, Al Jarreau, Kenny Loggins, The Manhattan Transfer, Herbie Hancock, Phil Collins e Philip Bailey.",
  casting_teddy_text:
    "Direttore musicale: Britney Spears, 98 Degrees, Mary Mary, Deborah Cox, Kelly Price, Ann Nesby, Paul Jackson Jr. Still Small Voice Euge Groove Data di riproduzione Kelly Clarkson Riconoscimenti Stevie Wonder A Time to Love Herbie Hancock Possibilità",
  casting_tariqh_text:
    "Tariqh ha registrato con artisti come Josh Groban, Christina Aguilera, Elton John, Herbie Hancock, Patti LaBelle, Luther Vandross, Al Jarreau, David Sanborn, Boyz II Men, Stevie Wonder, Smokey Robinson, Alicia Keys, Natalie Cole, Kenny Rogers, Aretha Franklin, Toni Braxton, The Temptations, Jennifer Lopez, Jennifer Love Hewitt, Babyface, David Benoit, Lou Rawls, Carlos Santana, Bette Midler.",
  casting_david_text:
    "David ha lavorato con Patti Austin, Walter Beasley, Joe Sample, George Duke, Al Jarreau, Dizzy Gillespie, David Sanborn, Lalah Hathaway e Marcus Miller. il gruppo Sneaky Jones, Eric Clapton.",
  casting_michael2_text:
    "Michael ha lavorato con Christina Aguilera, Ricky Martin, Destiny's Child, Stevie Wonder, Liza Minelli, Aretha Franklin, Ray Charles. È stato direttore musicale di Madonna, Jennifer Lopez, Rod Stewart, Michael Jackson tra gli altri. Ha anche lavorato con importanti artisti jazz come Herbie Mann e Herbie Hancock.",
  casting_luis_text:
    'Luis è apparso in The Latin Fusion Group Caldera, così come con Madonna, Al Di Meola, Andy Narell, Pat Metheny Group, "Band Of Legends" di James Taylor. Alex Acuña, Jaguares, Larry Klimas e David Garfield, Phil Collins.',
  casting_jerry_text:
    "Organizzato e condotto strumenti in ottone dell'orchestra per questo album <br/> Larry HALL TPT + FLUG <br/> Gary Grant TPT + FLUG <br/> Bill REICHENBACH + TROMBONE <br/> Dan HIGGINS SAX + FLUTE <br/><br/> L'orchestratore Jerry ha lavorato come musicista, arrangiatore e direttore d'orchestra per Give Me the Night di George Benson; Off the Wall, Thriller e Bad di Michael Jackson, I Know You, Chaka Khan di Chaka Khan, I Live You, Boogie Down di Al Jarreau, Let Earth's Wind & Fire, Groove di Toto, Rosanna di Toto, Behind the Groove di Teena Marie, Bad Girls di Donna Summers.",
  casting_dan_text:
    "Sax, Dan ha lavorato con Aerosmith, Stevie Wonder, Neil Diamond, Al Jarreau, Maroon 5, Kenny Loggins, Barry Manilow, Elton John, Go West, The Temptations, Lionel Richie, Joe Cocker, Lisa Stansfield ed Eros Ramazzotti. Conosciuto anche come il suo sassofono Bleeding Gums Murphy di The Simpsons.",
  casting_eddie_text:
    "Eddie ha organizzato e diretto i cori (ha lavorato come cantante, arrangiatore e direttore. È conosciuta per il suo lavoro in La La Land (2016), Hidden Figures (2016) e Hairspray Live! (2016).",
  casting_dionne_text:
    "Originario di Londra, Dionne è finalista di Xfactor. Le sue voci sono state registrate negli studi di Abbey Road a Londra, Regno Unito, l'ingegnere del suono Sam Okeel.",
  casting_eric_text:
    "Eric è dello Zimbabwe, registrato presso Cap Studio (Sudafrica). Registrato al Nut House Studio (CapeTown), l'ingegnere del suono Beyers Du Toit.",
  casting_string_text:
    "Corde dirette da Chris Walden, registrate al Los Angeles Bridge Studio dall'ingegnere del suono Bill Smith.",
  casting_bill_text:
    'L\'ingegnere del suono nominato da Grammy Bill Smith ha registrato e mixato l\'album (Rhythm First Section) presso "Studio Conway" (Los Angeles USA), (archi) presso "Studio The Bridge" (Los Angeles USA ) e registrazioni principalmente nello studio "The Village" (Los Angeles USA): casting, aggiunte sezioni ritmiche, voci, cori, assoli, ri-registrazione di chitarre e percussioni, ottoni, sax e mix dell\'album.',
  casting_bernie_text:
    "Bernie Grundman è il nome più importante e conosciuto nel campo del Mastering in Musica nel mondo.",
  casting_jon_text:
    "Jon era il chitarrista dei Siouxsie and the Banshees e Sinéad O’Connor. È un produttore di musica e video, fondatore del Batcave Club. <br/><br/> Musicista e graphic designer, Sophie è caporedattore di una rivista d'arte internazionale.",
  casting_marc_text:
    "Marc è un produttore discografico e regista francese di spettacoli. È il produttore esecutivo di questo album e ha prodotto tutte le canzoni musicalmente.",

  // News
  news_title: "News",
  news_subtitle: "Cosa c'è di nuovo oggi:",

  // Special Thanks
  special_thanks_title: "Ringraziamenti speciali",
  special_thanks_text1:
    "Grazie ai nostri primi investitori Grégory Bulanger, Bertrand Cohen.",
  special_thanks_text2:
    "Un ringraziamento speciale al nostro amico, fedele partner e principale investitore Olivier COURIOL.",

  // Credits
  credits_title: "Credits",
  credits_composer: "Compositore:",
  credits_lyrics: "Testi:",
  credits_production: "Produzione (direzione musicale):",
  credits_executive_producer: "Produttore esecutivo:",
  credits_recorded: "Album registrato a:",
  credits_sound_engineer: "Tecnico del suono (registrazioni e mix):",
  credits_images: "Immagini:",
  credits_record_cover: "Concetto di copertina:",
  credits_illustration: "Disegno:",
  credits_layout: "Disposizione:",
  credits_copyright: "Copyright:",
  credits_publishing: "Publishing:",
  credits_album_track: "Elenco di titoli",
  credits_webmaster: "Webmaster:",
  credits_text1:
    'Testi e musica depositati "United States Copyright Office, Washington".',
  credits_text2: "Marc NEGRONI",
  credits_text3:
    "CONWAY, Los Angeles (sezione ritmi) assistenti tecnici: Seth Waldmann e Rachel Findlen;<br/> THE BRIDGE, Los Angeles (corde) assistenti tecnici: Milton Gutierrez; <br/> THE VILLAGE, Los Angeles (voci, voce di sottofondo, ottoni, chitarre, Sax Solos e Mix) assistente ingegneri: Steve Bone, Eric Denniston, Alex Williams; <br/> ABBEY ROAD STUDIOS, ingegnere di Londra (registrazione vocale di Dionne Mitchell): Sam Okeel; <br/> NUT HOUSE STUDIO, ingegnere di CapeTown (registrazione vocale di Eric Phely Moyo): Beyers Du Toit",

  // Buy
  buy_title: "In Vendita",
  buy_text: "Nota: attualmente disponibile solo negli Stati Uniti d'America.",
  buy_buy_usa: "Acquista negli Stati Uniti",
  buy_buy_world: "Acquista nel resto del mondo",
  buy_text_world:
    "Nota: PRESTO DISPONIBILE Disponibile solo nel resto del mondo al di fuori degli Stati Uniti in questo momento.",

  // Web Links
  web_links_title: "Web Links",
};

export default it;
