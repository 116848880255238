import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";

import loveOnly from "../assets/videos/love_only.mp4";
import neverReturn from "../assets/videos/never_return.mp4";
import shallowHeaven from "../assets/videos/shallow_heaven.mp4";
import tellMeMyWay from "../assets/videos/tell_me_my_way.mp4";
import doYouListenToLoveSongsAlone from "../assets/videos/do_you_listen_to_love_songs_alone.mp4";

const styles = {
  title: {
    color: colors.purple,
    "& h3": {
      textAlign: "left",
    },
  },
  video: {
    width: "100%",
  },
};

const Videos = (props) => {
  const { classes } = props;

  const renderVideo = (src) => {
    return (
      <Grid item>
        <video controls={true} className={classes.video}>
          <source src={src} type="video/mp4" />
        </video>
      </Grid>
    );
  };

  return (
    <Section section="videos" setActiveSection={props.setActiveSection}>
      <Grid item>
        <Grid container direction="column">
          <Grid item className={classes.title}>
            <h3>Love Only</h3>
          </Grid>
          {renderVideo(loveOnly)}
          <Grid item className={classes.title}>
            <h3>Never Return</h3>
          </Grid>
          {renderVideo(neverReturn)}
          <Grid item className={classes.title}>
            <h3>Shallow Heaven</h3>
          </Grid>
          {renderVideo(shallowHeaven)}
          <Grid item className={classes.title}>
            <h3>Tell Me My Way</h3>
          </Grid>
          {renderVideo(tellMeMyWay)}
          <Grid item className={classes.title}>
            <h3>Do you listen to love songs alone</h3>
          </Grid>
          {renderVideo(doYouListenToLoveSongsAlone)}
        </Grid>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(Videos);
