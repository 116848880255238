import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import VizSensor from "react-visibility-sensor";

import Grid from "@material-ui/core/Grid";

import colors from "../assets/style/colors";

const styles = (theme) => ({
  container: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 50px",
    },
  },
  title: {
    color: colors.purple,
  },
});

const Section = (props) => {
  const { classes, t, section, children, setActiveSection } = props;

  return (
    <Grid
      item
      className={classes.container}
      id={section}
      style={{ flexShrink: 0 }}
    >
      <Grid container direction="column">
        <Grid item className={classes.title}>
          <VizSensor
            scrollCheck={true}
            offset={{ bottom: window.innerHeight / 2 }}
            onChange={(isVisible) => {
              if (isVisible) {
                setActiveSection(section);
              }
            }}
          >
            <h1>
              {section === "index" ? t("index_title") : t(`menu_${section}`)}
            </h1>
          </VizSensor>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(Section));
