import React from "react";
import { withStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

import Section from "./Section";

import colors from "../assets/style/colors";
import images from "../assets/img/images";

const styles = {
  link: {
    color: colors.purple,
  },
};

const WebLinks = (props) => {
  const { classes } = props;

  return (
    <Section section="web_links" setActiveSection={props.setActiveSection}>
      <Grid item>
        <img
          src={images.facebook}
          alt="facebook_page"
          style={{ maxWidth: "100%" }}
        />
      </Grid>
      <Grid item style={{ textAlign: "left" }}>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/Guy-Bulanger-489895794377625/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Facebook
            </a>
          </li>
        </ul>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(WebLinks);
