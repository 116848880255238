const es = {
  // Language
  language_coming_soon:
    "Coming soon... The link for the regional web site you selected will be available in the near future - please continue to ENTER this site for now.",

  // Menu
  menu_index: "Index",
  menu_intro: "Intro",
  menu_videos: "Videos",
  menu_casting: "Casting",
  menu_news: "News",
  menu_special_thanks: "Gracias especiales",
  menu_credits: "Credits",
  menu_buy: "En Venta",
  menu_web_links: "Web Links",
  menu_music_publishing: "Music Publishing",
  menu_contact: "Contact",

  // Index
  index_title: "Guy Bulanger Love Songs",
  index_subtitle: "Un álbum de canciones emotivas.",

  // Intro
  intro_title: "Intro",
  intro_picture_caption: "(GUY BULANGER 07/07/1950 –12/10/2017)",
  intro_text0:
    "Guy BULANGER falleció en octubre de 2017 mientras componía su próximo proyecto: un musical espectacular.",
  intro_text1:
    "Guy fue el compositor francés de la música en este álbum. Escribió música para una amplia variedad de cantantes franceses. Guy falleció en octubre de 2017 mientras componía su próximo proyecto: un musical espectacular.",
  intro_text2:
    'Tuvimos la increíble oportunidad de producir este álbum: "Guy Bulanger Love Songs", en Los Ángeles, con los mejores músicos y técnicos en el negocio de la música, gracias al patrocinio de algunos amigos.',
  intro_text3:
    "Este álbum existe, puedes probarlo viendo estos cinco videos promocionales que hicimos.",
  intro_text4:
    "Por supuesto, puedes comprar el álbum ahora distribuido a través de (....... «........ ..... ..... ............ ») De los servicios de streaming y las tiendas de música en línea más grandes donde comprar MP3.",

  // Casting
  casting_title: "Casting",
  casting_guitar: "Guitarra",
  casting_bass: "Bajo",
  casting_drums: "Tambores",
  casting_keyboards: "Teclados",
  casting_piano: "Piano",
  casting_percussion: "Percusión",
  casting_horn_player: "Trompetista",
  casting_saxophone: "Saxofón",
  casting_arranger: "Arreglista",
  casting_singer: "Cantante",
  casting_engineer: "Ingeniero de sonido",
  casting_mastering: "Mastering (Los Angeles USA)",
  casting_lyrics: "Letra",
  casting_executive_producer: "Productor ejecutivo",
  casting_string_section: "Orquesta de cuerdas",
  casting_la_soul: "L.A SOUL (*) COROS",
  casting_guy_text:
    "Guy fue el compositor francés de la música para este álbum. Escribe música para una amplia variedad de cantantes franceses. Guy murió en octubre de 2017 mientras componía su próximo proyecto: una comedia musical espectáculo.",
  casting_michael_text:
    "Michael ha grabado álbumes con artistas como Michael Jackson, Whitney Houston, Céline Dion, 'N Sync, Toni Braxton, Phil Collins, Rod Stewart, Joe Sample, The Scorpions, Vince Neil, Christina Aguilera, Michael Bolton, Mariah Carey, Bette Midler, Madonna, Babyface, En Vogue, Gloria Estefan, Stanley Clarke y Ricky Martin.",
  casting_nathan_text:
    "Nathan grabó, interpretó y coescribió canciones con Barry White, Anita Baker, The Love Unlimited Orchestra, Babyface, Bb King, Eric Clapton, George Harrison, Elton John, Laura Pausini, Gail Ann Dorsey, Michael Jackson, Stevie Wonder, Bryan Ferry , Savage Garden, Sting, Quincy Jones, Al Jarreau, Kenny Loggins, The Manhattan Transfer, Herbie Hancock, Phil Collins y Philip Bailey.",
  casting_teddy_text:
    "Director Musical: Britney Spears, 98 Grados, Mary Mary, Deborah Cox, Kelly Price, Ann Nesby, Paul Jackson Jr. Aún Pequeña Voz Euge Groove Fecha De Reproducción Kelly Clarkson Agradecimientos Stevie Wonder Un Momento Para Amar Las Posibilidades De Herbie Hancock.",
  casting_tariqh_text:
    "Tariqh ha grabado con artistas como Josh Groban, Christina Aguilera, Elton John, Herbie Hancock, Patti LaBelle, Luther Vandross, Al Jarreau, David Sanborn, Boyz II Men, Stevie Wonder, Smokey Robinson, Alicia Keys, Natalie Cole, Kenny Rogers, Aretha Franklin, Toni Braxton, The Temptations, Jennifer Lopez, Jennifer Love Hewitt, Babyface, David Benoit, Lou Rawls, Carlos Santana, Bette Midler.",
  casting_david_text:
    "David Trabajó Con Patti Austin, Walter Beasley, Joe Sample, George Duke, Al Jarreau, Dizzy Gillespie, David Sanborn, Lalah Hathaway Y Marcus Miller. El Grupo Sneaky Jones, Eric Clapton.",
  casting_michael2_text:
    "Michael ha trabajado con Christina Aguilera, Ricky Martin, Destiny’s Child, Stevie Wonder, Liza Minelli, Aretha Franklin, Ray Charles. Fue director musical de Madonna, Jennifer Lopez, Rod Stewart, Michael Jackson entre otros. También ha trabajado con notables artistas de jazz como Herbie Mann y Herbie Hancock.",
  casting_luis_text:
    'Luis tocó en la banda de fusión latina Caldera, Madonna, Al Di Meola, Andy Narell, Pat Metheny Group,"Band of Legends" de James Taylor. Alex Acuña, Jaguares, Larry Klimas y David Garfield, Phil Collins.',
  casting_jerry_text:
    "Arregló y dirigió las pistas de bronce para este álbum. <br/> Larry HALL TPT + FLUG <br/> Gary Grant TPT + FLUG <br/> Bill REICHENBACH + TROMBONE <br/> Dan HIGGINS SAX + FLUTE <br/><br/> El orquestador de Jerry ha trabajado como músico, arreglista y director para Give Me the Night de George Benson; Michael Jackson's Off the Wall, Thriller and Bad, Chaka Khan's I Know You, I Live You, Al Jarreau's Boogie Down, Earth Wind & Fire’s Let’s Groove, Toto's Rosanna, Teena Marie's Behind the Groove, Donna Summers ’Bad Girls.",
  casting_dan_text:
    "Jugador de saxofón e instrumento de viento, Dan trabajó con Aerosmith, Stevie Wonder, Neil Diamond, Al Jarreau, Maroon 5, Kenny Loggins, Barry Manilow, Elton John, Go West, The Temptations, Lionel Richie, Joe Cocker, Lisa Stansfield y Eros Ramazzotti. También conocido como su saxofón Murphy Gums Murphy de The Simpsons.",
  casting_eddie_text:
    "Eddie arregló y dirigió los coros (trabajó como cantante, arreglista y directora. Es conocida por su trabajo en La La Land (2016), Hidden Figures (2016) y Hairspray Live! (2016).",
  casting_dionne_text:
    "Originaria de Londres, Dionne es finalista de Xfactor. Sus voces fueron grabadas en los estudios de Abbey Road en Londres, Reino Unido, el ingeniero de sonido Sam Okeel.",
  casting_eric_text:
    "Eric es De Zimbabwe, Grabado En Cap Studio (Sudáfrica). Grabado En Nut House Studio (Cape Town), Ingeniero De Sonido Beyers Du Toit.",
  casting_string_text:
    "Cuerdas conducidas por Chris Walden, grabadas en Bridge Studio en Los Ángeles por el ingeniero de sonido Bill Smith",
  casting_bill_text:
    'El ingeniero de sonido nominado al Grammy Bill Smith grabó y mezcló el álbum (Sección Ritmo) en "Studio Conway" (Los Ángeles, EE. UU.), (Cuerdas) en "Studio The Bridge" (Los Ángeles, EE. UU.) ), y grabaciones principalmente en el estudio "The Village" (Los Ángeles, EE. UU.): castings, secciones de ritmo adicionales, voces, coros, solos, regrabación de guitarras y percusión, metales, saxos y mezclas del álbum.',
  casting_bernie_text:
    "Bernie Grundman es el nombre más destacado e importante en el campo de Music Mastering en el mundo.",
  casting_jon_text:
    "Jon fue el guitarrista de Siouxsie and the Banshees y Sinéad O’Connor. Es productor de música y video, fundador del Batcave Club. <br/><br/> Músico y diseñador gráfico, Sophie es editora en jefe de una revista internacional de arte.",
  casting_marc_text:
    "Marc es productor discográfico y director de espectáculos franceses. Él es el productor ejecutivo de este álbum y ha producido todas las canciones musicalmente.",

  // News
  news_title: "News",
  news_subtitle: "Qué hay de nuevo hoy:",

  // Special Thanks
  special_thanks_title: "Gracias especiales",
  special_thanks_text1:
    "Gracias a nuestros primeros inversores Grégory Bulanger, Bertrand Cohen.",
  special_thanks_text2:
    "Un agradecimiento especial a nuestro amigo, socio leal e inversor principal Olivier COURIOL.",

  // Credits
  credits_title: "Credits",
  credits_composer: "Compositor:",
  credits_lyrics: "Textos:",
  credits_production: "Producción (dirección musical):",
  credits_executive_producer: "Productor Ejecutivo:",
  credits_recorded: "Álbum grabado en:",
  credits_sound_engineer: "Ingeniero de sonido (grabaciones y mezclas):",
  credits_images: "Imágenes:",
  credits_record_cover: "Concepto de Cover:",
  credits_illustration: "Ilustración:",
  credits_layout: "Diseño:",
  credits_copyright: "Copyright:",
  credits_publishing: "Publishing:",
  credits_album_track: "Album Track Listing",
  credits_webmaster: "Webmaster:",
  credits_text1:
    'Letras y música depositadas en la "Oficina de Derechos de Autor de los Estados Unidos, Washington".',
  credits_text2: "All songs produced by Marc NEGRONI",
  credits_text3:
    "CONWAY, Los Ángeles (sección de ritmo) ingenieros asistentes: Seth Waldmann y Rachel Findlen; <br/> THE BRIDGE, Los Ángeles (sogas) ingenieros asistentes: Milton Gutiérrez; <br/> THE VILLAGE, Los Ángeles (voces, coros, metales, guitarse, Sax Solos y Mix) ingenieros asistentes: Steve Bone, Eric Denniston, Alex Williams; <br/> ABBEY ROAD STUDIOS, ingeniero de Londres (grabación de voz de Dionne Mitchell): Sam Okeel; <br/> NUT HOUSE STUDIO, ingeniero de CapeTown (grabación de voz de Eric Phely Moyo): Beyers Du Toit",

  // Buy
  buy_title: "En Venta",
  buy_text:
    "Nota: ACTUALMENTE solo está disponible en los Estados Unidos de América.",
  buy_buy_usa: "Comprar en los USA",
  buy_buy_world: "Comprar en el resto del mundo",
  buy_text_world:
    "Nota: PRÓXIMAMENTE Solo disponible en el resto del mundo fuera de los Estados Unidos en este momento.",

  // Web Links
  web_links_title: "Web Links",
};

export default es;
